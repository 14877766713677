<template>
  <div class="w-screen h-screen flex justify-center items-center">
    <div
      class="rounded-lg shadow-md flex flex-col w-full h-screen m-0 tabletLandscape:flex-row flex-nowrap desktopHighResolution:w-[75%] desktopHighResolution:h-[80%]"
    >
      <slot />
    </div>
  </div>
</template>
<script setup></script>
