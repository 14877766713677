<template>
  <div
    ref="buttonNew"
    class="buttonNew absolute right-3 h-fit w-fit cursor-pointer opacity-100 z-[1] transition-opacity duration-[100ms] ease-in-out"
  >
    <div class="relative">
      <font-awesome-icon
        v-click-outside="onClickOutside"
        class="py-1.5 px-3 text-neutral-200 bg-white border border-neutral-80 rounded rotate-90 hover:text-black"
        :class="{
          '!border-none !bg-transparent': iconOnly,
          'hover:!bg-neutral-80 !py-1.5 !px-3 !rounded-lg ': grayHover
        }"
        icon="fas fa-ellipsis-v"
        @click.prevent.stop="onOpen($event)"
      />
      <div
        v-if="showPopup"
        class="popup absolute hidden top-0 right-0 bg-white min-w-[150px] max-w-[300px] translate-y-0 shadow-sm border border-neutral-80 p-2 rounded"
        :class="{ 'rounded-lg': rounded }"
      >
        <div v-if="title" class="flex items-center justify-between mb-1">
          <span class="pl-1 uppercase text-black text-xs block">{{
            $t('shared.label.actions')
          }}</span>
          <font-awesome-icon
            class="icon text-neutral-200 hover:text-black"
            data-cy="close_edit"
            icon="fa-close"
            @click.prevent.stop="onClose($event)"
          />
        </div>
        <div
          class="flex flex-col text-sm [&>*]:px-1 [&>*]:py-0.5 [&>*]:text-neutral-200 [&>*]:rounded [&>*:hover]:bg-neutral-50"
          @click="onClose($event)"
        >
          <slot name="option" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import i18n from '@/i18n'
import { nextTick, ref } from 'vue'

const $t = i18n.t

const props = defineProps({
  iconOnly: Boolean,
  grayHover: Boolean,
  rounded: Boolean,
  title: {
    type: Boolean,
    default: true
  }
})

const showPopup = ref(false)
const buttonNew = ref(null)

function onOpen(e) {
  showPopup.value = true
  nextTick(() => {
    let activeBtn = document.querySelector('.buttonNew--active')
    if (activeBtn) {
      activeBtn.classList.remove('buttonNew--active')
      activeBtn.classList.remove('!z-[2]')
    }
    let popups = [...document.querySelectorAll('.popup--active')]
    if (popups.length > 0) {
      popups[0].classList.remove('popup--active')
      popups[0].classList.remove('!block')
    }
    let children = [...e.target.parentElement.children]
    children.forEach((child) => {
      let childExists = child.classList.contains('popup')
      if (childExists) {
        child.classList.add('popup--active')
        child.classList.add('!block')
        buttonNew.value.classList.add('buttonNew--active')
        buttonNew.value.classList.add('!z-[2]')
      }
    })
  })
}
function onClose(e) {
  nextTick(() => {
    const btn = buttonNew.value
    if (btn) {
      btn.classList.remove('buttonNew--active')
      btn.classList.remove('!z-[2]')
    }
    if (document.querySelector('.popup')) {
      document.querySelector('.popup').classList.remove('!block')
    }
    let exists = e.target.closest('.popup').classList.contains('popup--active')
    if (exists) {
      e.target.closest('.popup').classList.remove('!block')
    }
  })
}
function onClickOutside() {
  showPopup.value = false
}
</script>
