<template>
  <div
    class="py-[50px] w-full flex flex-col justify-center items-center relative"
  >
    <div
      class="noResult__arrow hidden absolute -top-[70px] right-[250px] desktop:!flex desktop:opacity-0 animate-bounceinnoresultanim"
    >
      <img
        class="transform translate-y-[-5%] transition ease duration-500 animate-float"
        src="@/assets/images/arrow.png"
      />
    </div>
    <video autoplay loop class="w-[550px] z-[-1]">
      <source src="@/assets/images/create-project-anim.mp4" type="video/mp4" />
    </video>
    <p class="text-neutral-200 font-medium text-2xl">
      {{ text }}
    </p>
  </div>
</template>
<script setup>
import { onMounted } from 'vue'
const prop = defineProps({
  text: String
})
onMounted(() => {
  const arrow = document.querySelector('.noResult__arrow')
  setTimeout(() => {
    arrow.style.opacity = 1
  }, 3000)
})
</script>
